import React from 'react'
import Layout from '../../components/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'

const TheOriginalTheatreOfTheCityOfNY = () => {
  const data = useStaticQuery(graphql`
    query {
        md: allMarkdownRemark (
        sort: { order: ASC fields: [frontmatter___volume_number]},
        filter: {fileAbsolutePath: {regex: "/the-original-theatre-of-the-city-of-new-york/.*.md$/"}}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              volume_number
              # sortorder
              notes
              cover_image
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <h1 className="mb-6 text-2xl lg:text-5xl">The Original Theatre of the City of New York: From the Mid-60s to the Mid-70s</h1>
      <section>
        {data.md.edges.map(({ node }, i) => (
          <div className={`py-12 ${i !== (data.md.edges.length - 1) ? 'border-b border-gray-900' : ''}`} key={node.id}>
            <Link to={node.fields.slug}>
              <h2 className="mb-6 text-xl">Book {node.frontmatter.volume_number}: {node.frontmatter.title}</h2>
            </Link>
            <div className="flex img-container">
            { node.frontmatter.cover_image && node.frontmatter.cover_image.map(src => <img src={`/images${src}`} alt={node.frontmatter.title} key={src} className="w-48" />) }
            </div>
            { node.frontmatter.notes && node.frontmatter.notes.map(note => <p className={node.frontmatter.notes.length > 1 ? 'pl-6' : ''} key={note}>{note}</p>) }
          </div>
        ))}
      </section>
    </Layout>
  )
}


export default TheOriginalTheatreOfTheCityOfNY
